import { Component, OnInit ,ViewEncapsulation} from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder,FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CampaignService } from './../../../shared/campaign.service';
import { KioskService } from './../../../shared/kiosk.service';
import { UserService } from './../../../shared/user.service';
import { FileService } from './../../../shared/file.service';
import { MatTableDataSource } from '@angular/material/table';
import { PagerService } from '../../../shared/pager.service';
import { element } from 'protractor';



export interface DeviceElement {
  Statut: string;
  actif: string;
  created: Date;
  title: string;
  Action: any;
}
const ELEMENT_DATA: DeviceElement[] = [];


@Component({
  selector: 'app-createcampaign',
  templateUrl: './createcampaign.component.html',
  styleUrls: ['./createcampaign.component.css',
  './bootstrap.css',
  './bs-switches.css'
],
  encapsulation: ViewEncapsulation.None
})
export class CreatecampaignComponent implements OnInit {
  form: FormGroup;

  displayedColumns: string[] = ['col1', 'col2', 'col3', 'col4', 'col5', 'col6', 'col7'];
  dataSource = new MatTableDataSource<DeviceElement>(ELEMENT_DATA);
  listDeviceData = [];
  kioskArray = [];
  filterState: string = "encours";
  pager: any = {};
  // paged items
  pagedItems: any[];
  private allItems: any[];
  validMsg;
  errorMsg;
  isIconNotSelected;
  listDevice: Boolean = false;
  listKiosk: Boolean = false;
  listKioskSearch: Boolean = false;
  listKioskEmpty : Boolean = false;
  isActive: Boolean = false;
  onOff;

  listIcon = [];
  urlpath : String = "";
  campaing = {
    title: '',
    description: '',
    icon: 'icon01',
    idOrganization: '',
    idDeviceList : [String]
  };

  constructor(private router: Router, 
              private campaignService: CampaignService, 
              private userService: UserService, 
              private pagerService: PagerService,
              private kioskService : KioskService,
              private fileService : FileService) {

                this.campaing.icon = 'icon01';
                this.urlpath = "https://app.e6kdonation.com/files/icones/";
                this.readListIconAvailable();
               }

  ngOnInit() {

    this.form = new FormGroup({
      title: new FormControl(),
      description: new FormControl()
   });
    this.userService.getUserProfile().subscribe(
      res => {
        this.campaing.idOrganization = res['user'].idOrganisation;
        this.campaing.icon = 'icon01';
        this.getlistDevice(this.campaing.idOrganization);
      }
    );
  }

  readListIconAvailable(){

    this.fileService.getIconListFiles().subscribe(res => {
      if(res !== undefined && res !== null ){
        this.initListIconFromServer(res);
      }
     
    });

  }
  initListIconFromServer(res){
    var urlPath = this.urlpath;
    var iconname = "iconXX.png";
    const regex = /XX/gi;
    res.data.forEach(element => {
      var name = urlPath + element.name;
      var obj = {
        id : element.name.substring(0, 6),
        icon : element.name,
        iconpath : element.urlpath
      }
      this.listIcon.push(obj);
      
    });  
    
  }

  initListIcon(){
    var urlPath = this.urlpath;
    var iconname = "iconXX.png";
    const regex = /XX/gi;
    for(var i = 1 ; i<23 ;i++){
      
        var icon = iconname.replace(regex,i.toString().padStart(2,'0'));
        var name = urlPath + icon;
        var obj = {
          id : icon.substring(0, 6),
          icon : icon,
          iconpath : name
        }
        this.listIcon.push(obj);
      
    }
    
  }

  switchKiosk(event){

    console.log(event);

  }
  getlistDevice(id) {
    var that = this;
    this.kioskService.getKioskOrganisation(id).subscribe(
      res => {
        console.log(res);

        var listData = res['kiosks'];
        listData = this.activeAllKiosk(listData);

        this.listDeviceData = listData;
        this.kioskArray = listData;
        this.allItems = listData;

        this.listDevice

        if (this.allItems.length !== 0) {
          this.listDevice = true;
          this.listKiosk = true;
          this.listKioskSearch = true;
          this.listKioskEmpty = false;
        } else {
          this.listKioskEmpty = true;
          this.listDevice = false;
          this.listKiosk = false;
          this.listKioskSearch = false;
        }
        this.setPage(1);

      },
      err => {
        console.log(err);
      }
    );
  }

  activeAllKiosk( aListKiosk){
      if(aListKiosk != undefined && aListKiosk !== null && Array.isArray(aListKiosk)){
        aListKiosk.forEach(element => {
          element.isActive = true;
        });
      }
      return aListKiosk;
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.dataSource = new MatTableDataSource<DeviceElement>(this.allItems);
  }

  searchKiosk(searchValue: string) {
    const kioskArray =  this.kioskArray.filter(x => x._id.toUpperCase().includes(searchValue.toUpperCase()));
    this.allItems = kioskArray;
    if (this.allItems.length > 0){
      this.listKiosk = true;
      this.listKioskSearch = true;
      this.listKioskEmpty = false;
    }else{
      this.listKioskSearch = false;
      this.listKiosk = true;
      this.listKioskEmpty = true;
    }
    this.setPage(1);
  }

  addIconCampaign(icon) {
    this.campaing.icon = icon;
  }

  getActiveKiosk(){
    var idKioskList = [];
    var ALL = true;
    this.allItems.forEach(element => {
      if (element.isActive == true){
        idKioskList.push(element.idKiosk);
      }else{
        ALL = false;
      }
    });
    if (ALL == true){
     // return "ALL";
    }    
    return idKioskList;
  }
  getFormSubmit() {
    this.campaing.title = this.form.value.title;
    this.campaing.description = this.form.value.description;
    this.campaing["idKioskList"] = this.getActiveKiosk();
    return this.campaing;
       
  }
  onSubmit(form: NgForm) {
    if (this.campaing.icon !== '' && this.form.status == "VALID") {
      this.campaing = this.getFormSubmit();      
      this.campaignService.addCampaign(this.campaing).subscribe(
        res => {
          this.validMsg = true;
          setTimeout(() => {
            this.router.navigateByUrl('/dashboard/campaign');
          }, 2000);
        },
        err => {
          this.errorMsg = true;
          setTimeout(() => {
            this.errorMsg = false;
          }, 2000);
        }
      );
    } else {
      this.isIconNotSelected = true;
    }
  }

}
