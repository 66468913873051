import { Component, OnInit ,ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KioskService } from '../../../shared/kiosk.service';
import { Kiosk } from '../../../shared/kiosk.model';
import { KioskServiceBis } from '../../../shared/kiosk.serviceBis';
import { StripeTransactionService } from '../../../shared/stripeTransaction.service';
import { UserService } from '../../../shared/user.service';
import {NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
interface Template {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-template-kiosk',
  templateUrl: './template-kiosk.component.html',
  styleUrls: ['./template-kiosk.component.css'],
  providers: [NgbCarouselConfig]
})


export class TemplateKioskComponent implements OnInit {
  
  kiosk: Kiosk;
  sumTransaction: any = 0;
  totalTransaction: any = 0;
  averageTransaction: any = 0;
  selectedTemplate: String;
  errorServer: Boolean;
  tabVisible : Boolean = false;
  displayNameChange: Boolean;
  displayNameInit: String;
  displayNameDefaultText:String;
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true; 

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  templates: Template[] = [
    {value: '111', viewValue: 'Bleu'},
    {value: '112', viewValue: 'Rouge'},
    {value: '113', viewValue: 'Vert'}
  ];
  images = [62, 83, 466, 965, 982, 1043, 738].map((n) => `https://picsum.photos/id/${n}/900/500`);



  imagesDiv = [{
    templateId : "111",
    templateName :"Azur"
  }, {
    templateId : "112",
    templateName :"Rubis"
  }, {
    templateId : "113",
    templateName :"Emeraude"
  }];
  urlpath : String = "";
  constructor(private kioskService: KioskService,
              private route: ActivatedRoute,
              private stripeTransactionService: StripeTransactionService,
              private userService: UserService,
              private kioskServiceBis: KioskServiceBis,
             
             ) {

              this.urlpath = "https://app.e6kdonation.com/files/template";
    
     // customize default values of carousels used by this component tree
    //  config.interval = 1500;
    //  config.wrap = false;
    //  config.keyboard = false;
    //  config.pauseOnHover = false;


    this.kiosk = new Kiosk();


    this.kiosk.address = {
           city: '',
           postaleCode: '',
           state: '',
           line01: '',
           latitude: '',
           longitude: ''
    };
   }
   
   togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

   onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  ngOnInit() {
    this.tabVisible = true;
    this.displayNameDefaultText = "";
    this.userService.getUserProfile().subscribe(
      res => {
        this.sumTransactionStripeByKioskOrganisation(res['user'].idOrganisation, this.route.snapshot.paramMap.get('id'));
        this.kioskByIdAndOrganisation(res['user'].idOrganisation, this.route.snapshot.paramMap.get('id'));
        this.getTemplateKiosk();
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  updateTemplateKiosk(idTemplate,event){
    this.kiosk.idTemplate = idTemplate;

    var data = {
      idTemplate : this.kiosk.idTemplate,
      id : this.kiosk.idTemplate
    }
    var newId = this.kiosk.idTemplate;
    this.kioskService.setTemplateKiosk(this.kiosk.idKiosk,data).subscribe(
      res => {
        this.selectedTemplate = newId;
      },
      err => {
        this.errorServer = true;
        this.kiosk.idTemplate = this.selectedTemplate; 
      }
    );

    console.log(this.kiosk.idTemplate);
  }
  getTemplateKiosk(){
    this.kioskService.getTemplateKiosk().subscribe(res=>{
      var list = res['template'];
      if (list !== undefined && list !== null){
        this.templates = [];
        list.forEach(element => {
          this.templates.push({
            value : element.id,
            viewValue : element.nameColor
          })
        });
      }
    },
    err => {
      this.errorServer = true;
    });
  }

setDisplayDefaultText(){

  if (this.kiosk.displayNameDefault === true){
    this.displayNameDefaultText = "Texte par défaut";
  }else{
    this.displayNameDefaultText = "Texte personalisé";
  }
}

  kioskByIdAndOrganisation(idO, idK) {
    this.kioskServiceBis.kioskByIdAndOrganisation(idO, idK).subscribe(
      res => {
        this.kiosk = res['kiosk'][0];

        if (this.kiosk.displayName === undefined || this.kiosk.displayName === null) {
          this.kiosk.displayName = this.kiosk.Organisation.organisationName;
        }
        this.displayNameInit = this.kiosk.displayName;

        if (this.kiosk.displayNameDefault === undefined || this.kiosk.displayNameDefault === null) {
          this.kiosk.displayNameDefault = false;
        
        }

       this.setDisplayDefaultText();

        this.selectedTemplate =  this.kiosk.idTemplate;
        console.log("this.kiosk");
        console.log(this.kiosk);
        // this.kiosk.address = {
        //   city: res['kiosk'][0].city,
        //   postaleCode: res['kiosk'][0].postaleCode,
        //   state: res['kiosk'][0].state,
        //   line01: res['kiosk'][0].line01,
        //   latitude: res['kiosk'][0].latitude,
        //   longitude: res['kiosk'][0].longitude,
        //     };
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  sumTransactionStripeByKioskOrganisation(idO, idK) {
    this.stripeTransactionService.sumTransactionStripeByKioskOrganisation(idO, idK).subscribe(
      res => {
        if (res['transactions'].length !== 0) {
            this.sumTransaction = res['transactions'][0].total;
            this.averageTransaction = res['transactions'][0].average;
            this.totalTransaction = res['transactions'][0].totalTransaction;
        }
      },
      err => {
        this.errorServer = true;
      }
    );
  }




  switchKiosk(event) {

    console.log(event);
    if (this.kiosk.displayNameDefault === true) {
      this.kiosk.displayName = this.kiosk.Organisation.organisationName;
      if (this.displayNameInit !== this.kiosk.displayName) {
        this.displayNameChange = true;
      }
    }else{
      this.kiosk.displayName = this.displayNameInit;
      this.changeTitle(this.kiosk.displayName.toString());
    }
    //
    this.setDisplayDefaultText();

  }
  updateDisplayNameKiosk() {

    var data = {
      displayName: this.kiosk.displayName,
      displayNameDefault: this.kiosk.displayNameDefault
    }
    var displayName = this.kiosk.displayName;
    this.kioskService.setDisplayNameKiosk(this.kiosk.idKiosk, data).subscribe(
      res => {
        this.displayNameInit = displayName;
        this.changeTitle(this.kiosk.displayName.toString());
      },
      err => {
        this.errorServer = true;
        this.kiosk.displayName =  this.displayNameInit;
        this.changeTitle(this.kiosk.displayName.toString());
      }
    );

    console.log(this.kiosk.idTemplate);
  }
  changeTitle(searchValue: string) {
    this.displayNameChange = false;
    this.kiosk.displayName = searchValue;
    if (this.displayNameInit !== searchValue) {
      this.displayNameChange = true;
    }

  }


}
